<template>
  <div class="statistics-quality-container">
    <div class="search-box">
      <Row>
        <Col span="24">
          <h2 class="search-title border-bottom">按条件搜索</h2>
        </Col>
      </Row>
      <Form class="search-form" :label-width="85" ref="formValidate" :model="searchForm" :rules="ruleValidate">
        <Row>
          <Col span="5">
            <Form-item label="会员姓名：">
              <Input v-model="searchForm.member_name" placeholder="请输入..."></Input>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="5">
            <Form-item label="性别：">
              <Select clearable v-model="searchForm.sex" placeholder="请选择">
                <Option :value="v.id" v-for="v in sexList" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="8">
            <Form-item label="质检员：">
              <Select clearable v-model="searchForm.booster" placeholder="请选择">
                <Option :value="v.id" v-for="v in staffList" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
        </Row>
        <Row>
          <Col span="7">
            <Form-item label="抽查时间：">
              <Date-picker @on-change="changeTime" type="daterange" placeholder="选择日期"></Date-picker>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="8">
            <Form-item label="年龄：">
              <Row>
                <Col span="10">
                  <Form-item prop="age_start">
                    <Input v-model="searchForm.age_start" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">至</Col>
                <Col span="10">
                  <Form-item prop="age_end">
                    <Input v-model="searchForm.age_end" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">岁</Col>
              </Row>
            </Form-item>
          </Col>
        </Row>
        <Row>
          <Col span="5">
            <Form-item label="门店服务：">
              <Select clearable v-model="searchForm.store_result" placeholder="请选择">
                <Option :value="v.id" v-for="v in branchList" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="5">
            <Form-item label="方案服务：">
              <Select clearable v-model="searchForm.plan_result" placeholder="请选择">
                <Option :value="v.id" v-for="v in planList" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="5">
            <Form-item label="促进服务：">
              <Select clearable v-model="searchForm.boost_result" placeholder="请选择">
                <Option :value="v.id" v-for="v in boostList" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="5">
            <Form-item label="医生服务：">
              <Select clearable v-model="searchForm.doctor_result" placeholder="请选择">
                <Option :value="v.id" v-for="v in doctorList" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
        </Row>
        <Row>
          <Col span="7">
            <Form-item label="省份：">
              <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="pro_name"></Input>
              <Select clearable v-model="searchForm.pid" placeholder="请选择" @on-change="changePro" v-else>
                <Option :value="v.id" v-for="v in proList" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="7">
            <Form-item label="城市：">
              <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="city_name"></Input>
              <Select clearable v-model="searchForm.cid" placeholder="请选择" @on-change="changeCity" v-else>
                <Option :value="v.id" v-for="v in cityList" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="7">
            <Form-item label="门店：">
              <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="branch_name"></Input>
              <Select clearable v-model="searchForm.sid" placeholder="请选择" v-else>
                <Option :value="v.id" v-for="v in storeList" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
        </Row>
        <div class="tac">
          <Button type="primary" class="search-btn" @click="handleSubmit('formValidate')">搜索</Button>
        </div>
      </Form>
    </div>
    <div class="export-div clearfix">
      <a href="javascript:;" class="export-btn" @click="exportData()" v-if="this.power.indexOf('数据统计--数据导出权限') > -1">导出数据</a>
    </div>
    <Table stripe :columns="columns" :data="qualityData" @on-selection-change="selectOne" ref="table"></Table>
    <Row>
      <Col span="12">
        <div class="table-oprate">
          <!--<Button @click="">删除</Button>-->
        </div>
      </Col>
      <Col span="12">
        <span class="records">共{{pageTotal}}条记录</span>
      </Col>
    </Row>
    <div class="page-nations-wrapper">
      <div class="page-nations">
        <Page :total="pageTotal" :current="searchForm.page" :page-size="searchForm.size" @on-change="changePage"></Page>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import qualityService from '@/services/qualityService';
import statisticsService from '@/services/statisticsService';
export default {
	data() {
		const ageSmall = (rule, value, callback) => {
			if (value === '') {
				if (this.searchForm.age_end !== '') {
					this.$refs.formValidate.validateField('age_end');
				}
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (this.searchForm.age_end !== '') {
						this.$refs.formValidate.validateField('age_end');
					}
					callback();
				}
			}
		};
		const ageLarge = (rule, value, callback) => {
			if (value === '') {
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (
						this.searchForm.age_start !== '' &&
						Number(value) < Number(this.searchForm.age_start)
					) {
						callback(new Error('请输入更大数值'));
					}
					callback();
				}
			}
		};
		return {
			searchForm: {
				page: 1,
				size: 10,
				member_name: '',
				sex: '',
				pid: '',
				cid: '',
				sid: '',
				start: '',
				end: '',
				age_start: '',
				age_end: '',
				booster: '',
				store_result: '',
				plan_result: '',
				boost_result: '',
				doctor_result: '',
			},
			exportForm: {
				member_name: '',
				sex: '',
				pid: '',
				cid: '',
				sid: '',
				start: '',
				end: '',
				age_start: '',
				age_end: '',
				booster: '',
				store_result: '',
				plan_result: '',
				boost_result: '',
				doctor_result: '',
			},
			sexList: [
				{
					id: 0,
					name: '男',
				},
				{
					id: 1,
					name: '女',
				},
			],
			staffList: [],
			branchList: [
				{
					id: 0,
					name: '不属实',
				},
				{
					id: 1,
					name: '属实',
				},
			],
			planList: [
				{
					id: 0,
					name: '不属实',
				},
				{
					id: 1,
					name: '属实',
				},
			],
			boostList: [
				{
					id: 0,
					name: '不属实',
				},
				{
					id: 1,
					name: '属实',
				},
			],
			doctorList: [
				{
					id: 0,
					name: '不属实',
				},
				{
					id: 1,
					name: '属实',
				},
			],
			proList: [],
			cityList: [],
			city: {},
			storeList: [],
			store: {},
			columns: [
				// { type: 'selection', width: 60, align: 'center' },
				{ title: '抽查时间', key: 'create_time', align: 'center' },
				{ title: '所属分院', key: 'branch_name', align: 'center' },
				{
					title: '会员姓名',
					key: 'member_name',
					align: 'center',
					render: (h, params) => {
						return h('div', [
							h(
								'a',
								{
									style: {
										color: '#ff8400',
									},
									attrs: {
										target: '_blank',
										href:
											'#/base/member/detail/' +
											this.qualityData[params.index].member_id +
											'/' +
											2 +
											'/' +
											1,
									},
								},
								params.row.member_name,
							),
						]);
					},
				},
				{
					title: '性别',
					key: 'sex',
					align: 'center',
					render: (h, params) => {
						if (params.row.sex === 0) {
							return h('div', '男');
						} else if (params.row.sex === 1) {
							return h('div', '女');
						} else {
							return h('div', params.row.sex);
						}
					},
				},
				{
					title: '年龄',
					key: 'age',
					align: 'center',
					render: (h, params) => {
						if (params.row.age === null) {
							return h('div', params.row.age);
						} else {
							return h('div', params.row.age + '岁');
						}
					},
				},
				{
					title: '门店服务 ',
					key: 'store_result',
					align: 'center',
					render: (h, params) => {
						if (params.row.store_result === 0) {
							return h('div', '不属实');
						} else if (params.row.store_result === 1) {
							return h('div', '属实');
						} else {
							return h('div', params.row.store_result);
						}
					},
				},
				{
					title: '方案服务 ',
					key: 'plan_result',
					align: 'center',
					render: (h, params) => {
						if (params.row.plan_result === 0) {
							return h('div', '不属实');
						} else if (params.row.plan_result === 1) {
							return h('div', '属实');
						} else {
							return h('div', params.row.plan_result);
						}
					},
				},
				{
					title: '促进服务 ',
					key: 'boost_result',
					align: 'center',
					render: (h, params) => {
						if (params.row.boost_result === 0) {
							return h('div', '不属实');
						} else if (params.row.boost_result === 1) {
							return h('div', '属实');
						} else {
							return h('div', params.row.boost_result);
						}
					},
				},
				{
					title: '医生服务 ',
					key: 'doctor_result',
					align: 'center',
					render: (h, params) => {
						if (params.row.doctor_result === 0) {
							return h('div', '不属实');
						} else if (params.row.doctor_result === 1) {
							return h('div', '属实');
						} else {
							return h('div', params.row.doctor_result);
						}
					},
				},
				{ title: '质检员', key: 'staff_name', align: 'center' },
				{
					title: '操作',
					key: 'action',
					width: 120,
					align: 'center',
					render: (h, params) => {
						return h('div', [
							h(
								'a',
								{
									style: {
										margin: '0 10px',
										color: '#ff8400',
									},
									attrs: {
										target: '_blank',
										href: '#/base/quality/detail/' + this.qualityData[params.index].id,
									},
								},
								'查看',
							),
						]);
					},
				},
			],
			qualityData: [],
			pageTotal: 0,
			ruleValidate: {
				age_start: [{ validator: ageSmall, trigger: 'blur' }],
				age_end: [{ validator: ageLarge, trigger: 'blur' }],
			},
			power: '',
			pro_name: '',
			city_name: '',
			branch_name: '',
		};
	},
	created() {
		this.power = localStorage.getItem('userPower');
		this.pro_name = JSON.parse(localStorage.getItem('userInfo')).user_info.pro_name;
		this.city_name = JSON.parse(localStorage.getItem('userInfo')).user_info.city_name;
		this.branch_name = JSON.parse(localStorage.getItem('userInfo')).user_info.branch_name;
		if (
			this.power.indexOf('数据统计--查询全国数据的权限') === -1 &&
			this.power.indexOf('数据统计--查询区域数据的权限') === -1
		) {
			this.searchForm.pid = JSON.parse(localStorage.getItem('userInfo')).user_info.pid;
			this.searchForm.cid = JSON.parse(localStorage.getItem('userInfo')).user_info.cid;
			this.searchForm.sid = JSON.parse(localStorage.getItem('userInfo')).user_info.sid;
		}
		this.getQualityData();
		qualityService.getQualitySelect().then((data) => {
			this.proList = data.branch.pro;
			this.city = data.branch.city;
			this.store = data.branch.store;
			this.staffList = data.staff_list;
		});
	},
	methods: {
		changePro() {
			this.cityList = this.city[this.searchForm.pid];
			this.searchForm.cid = '';
		},
		changeCity() {
			this.storeList = this.store[this.searchForm.cid];
			this.searchForm.sid = '';
		},
		getQualityData() {
			statisticsService.getQualityData(this.searchForm).then((data) => {
				this.qualityData = data.list;
				this.pageTotal = data.row_size;
			});
		},
		handleSubmit(name) {
			this.$refs[name].validate((valid) => {
				if (valid) {
					this.searchForm.page = 1;
					this.getQualityData();
					this.exportForm.member_name = this.searchForm.member_name;
					this.exportForm.sex = this.searchForm.sex;
					this.exportForm.pid = this.searchForm.pid;
					this.exportForm.cid = this.searchForm.cid;
					this.exportForm.sid = this.searchForm.sid;
					this.exportForm.start = this.searchForm.start;
					this.exportForm.end = this.searchForm.end || '';
					this.exportForm.booster = this.searchForm.booster;
					this.exportForm.store_result = this.searchForm.store_result;
					this.exportForm.plan_result = this.searchForm.plan_result;
					this.exportForm.boost_result = this.searchForm.boost_result;
					this.exportForm.doctor_result = this.searchForm.doctor_result;
					this.exportForm.age_start = this.searchForm.age_start;
					this.exportForm.age_end = this.searchForm.age_end;
				}
			});
		},
		changeTime(date) {
			this.searchForm.start = date[0];
			this.searchForm.end = date[1];
		},
		changePage(page) {
			this.searchForm.page = page;
			this.getQualityData();
		},
		selectOne() {},
		exportData() {
			location.href =
				'http://conlife.sskh.net:9928/api/quality/export?member_name=' +
				this.exportForm.member_name +
				'&sex=' +
				this.exportForm.sex +
				'&pid=' +
				this.exportForm.pid +
				'&cid=' +
				this.exportForm.cid +
				'&sid=' +
				this.exportForm.sid +
				'&start=' +
				this.exportForm.start +
				'&end=' +
				this.exportForm.end +
				'&age_start=' +
				this.exportForm.age_start +
				'&age_end=' +
				this.exportForm.age_end +
				'&booster=' +
				this.exportForm.booster +
				'&store_result=' +
				this.exportForm.store_result +
				'&plan_result=' +
				this.exportForm.plan_result +
				'&boost_result=' +
				this.exportForm.boost_result +
				'&doctor_result=' +
				this.exportForm.doctor_result +
				'&staff_name=' +
				this.exportForm.staff_name;
		},
	},
};
</script>

<style lang="css" scoped>
.statistics-quality-container .search-box {
  border: 1px solid #d7dde4;
}
</style>
